import { ApiRequest } from "api/ApiRequest";
import { StripeAccountOnboardingStatus } from "../stripe/isValidStripeAccountOnboardingStatus";
import { StripeAccountPayoutsStatus } from "../stripe/isValidStripeAccountPayoutsStatus";
import { emit } from "../pubSub";

import {
	getAccountStatus,
	Output as GetAccountStatusOutput
} from "api/getAccountStatus";

export interface AccountStatus {
	isMentor: boolean;
	timezone: string;
	locationDistrict?: string;
	locationCountryCode?: string;
	locationCountryName?: string;
	hasLinkedZoomAccount: boolean;
	stripeAccountOnboardingStatus: StripeAccountOnboardingStatus;
	stripeAccountPayoutsStatus: StripeAccountPayoutsStatus;
	displayName: string;
	mentorProfileAboutMe: string;
	terms: GetAccountStatusOutput["terms"];
	hasDismissedStripeAlert: boolean;
	defaultCurrencyCode: string;
	skypeId: string;
	defaultGoogleMeetLink: string;
	icalKey: string;
	hasPaidZoomAccount: boolean;

	paymentProvidersMBWay: string;
	paymentProvidersWisetag: string;
	paymentProvidersCustom: string;

	_populated: boolean;
}

export const accountStatus: Partial<AccountStatus> = {
	_populated: false
};

function setAccountStatus(newValues: Omit<AccountStatus, "_populated">) {
	Object.assign(accountStatus, newValues);
	accountStatus._populated = true;
	emit("accountStatusChanged", accountStatus);
	return accountStatus as AccountStatus;
}

function clear() {
	const keys = Object.keys(accountStatus) as (keyof AccountStatus)[];
	for (const k of keys) {
		delete accountStatus[k];
	}
	accountStatus._populated = false;
	emit("accountStatusChanged", accountStatus);
}

export function refreshFromApi(
	uid: string,
	auth: { uid?: string }
): ApiRequest<Omit<AccountStatus, "_populated">> {
	const req = getAccountStatus({ uid }, auth);

	const ready = req.ready.then(response => {
		if (!response) {
			return;
		}

		// TODO:WV:2023040:Validate new data

		const newAccountStatus = setAccountStatus(response);
		return newAccountStatus;
	});

	return {
		ready,
		abort: () => {
			req.abort();
		},
		aborted: () => req.aborted(),
		type: req.type
	};
}

// It is important to call this when the user's UID changes, arrives, or disappears, to prevent errors.
export function respondToAuthChange(uid?: string) {
	if (!uid) {
		clear();
		return;
	}
	return refreshFromApi(uid, { uid });
}
