import React, { useEffect } from "react";

import { ModalWizardStepRenderProps } from "components/ModalWizard/ModalWizard";
import { WizardData } from "../PaymentWizard";

import { useUserProfile } from "hooks/useUserProfile";
import { useAccountStatus } from "hooks/useAccountStatus";
import terminology from "terminology.json";
import { formatPrice } from "services/formatPrice";

function StepInstructions({
	enableProgressToNextStep,
	disableProgressToNextStep,
	wizardData: { paymentDetails, mentorId, paymentProvider }
}: ModalWizardStepRenderProps<WizardData>) {
	const { output } = useUserProfile({
		uid: mentorId
	});

	const { locationCountryCode } = useAccountStatus();

	useEffect(() => {
		enableProgressToNextStep({});
	}, [enableProgressToNextStep]);

	if (!output) {
		return null;
	}

	// TODO:WV:20230430:Cope better with missing data, e.g. locationCountrycode, paymentDetails, discrepancy between users choice and supposedly available payment options, etc.
	const prefix = `Please send the full value ${
		paymentDetails && locationCountryCode
			? "of " +
			  formatPrice({
					currencyCode: paymentDetails.currency,
					amount: paymentDetails.price,
					locationCountryCode
			  })
			: ``
	} to `;
	const defaultText = `Please contact your ${terminology.mentor} for instructions on how to pay`;
	return (
		<>
			{paymentProvider === "mbway"
				? output.paymentProvidersMBWay
					? prefix + `${output.paymentProvidersMBWay}`
					: defaultText
				: null}
			{paymentProvider === "wise"
				? prefix + `Wisetag ${output.paymentProvidersWisetag}`
				: null}
			{paymentProvider === "custom"
				? output.paymentProvidersCustom
					? output.paymentProvidersCustom
					: defaultText
				: null}
		</>
	);
}

export default StepInstructions;
