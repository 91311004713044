import React from "react";
import styled from "styled-components";

const SectionGroupInner = styled.div`
	border: 1px solid #eaeaea;
	position: relative;
	& > h2 {
		position: absolute;
		top: -0.7em;
		left: 0.5em;
		background-color: white;
		padding: 0 0.5em;
		border: 1px solid #eaeaea;
	}
	margin-bottom: 2em;

	padding: 3em 1em 0 1em;
`;

type Props = {
	title: string;
};

const SectionGroup: React.FunctionComponent<Props> = ({ children, title }) => {
	return (
		<SectionGroupInner>
			<h2>{title}</h2>

			{children}
		</SectionGroupInner>
	);
};

export default SectionGroup;
