import React, { useState } from "react";
import { Link } from "react-router-dom";

import PageContent from "components/PageContent";
import AvailabilitySection from "components/PageDashboard/AvailabilitySection";
import ExternalCommitmentsSection from "components/PageDashboard/ExternalCommitmentsSection";
import SessionTypesSection from "components/PageDashboard/SessionTypesSection";
import SingleSessionPricesSection from "components/PageDashboard/SingleSessionPricesSection";
import PackagePricesSection from "components/PageDashboard/PackagePricesSection";
import ContactsAndMessagesSection from "components/PageDashboard/ContactsAndMessagesSection";
import ReceivingMoneySection from "components/PageDashboard/ReceivingMoneySection";
import MeetingRequestsSection from "components/PageDashboard/MeetingRequestsSection";
import PackageRequestsSection from "components/PageDashboard/PackageRequestsSection";
import MeetingsSection from "components/PageDashboard/MeetingsSection";
import PackagesSection from "components/PageDashboard/PackagesSection";
import AccountSettingsSection from "components/PageDashboard/AccountSettingsSection";
import ProfilePhotoSection from "components/PageDashboard/ProfilePhotoSection";
import ICalFeedsToImportSection from "components/PageDashboard/ICalFeedsToImportSection";
import DeleteAccountSection from "components/PageDashboard/DeleteAccountSection";
import ProfileUrlSection from "components/PageDashboard/ProfileUrlSection";
import ProfileVideoSection from "components/PageDashboard/ProfileVideoSection";
import SkypeIdSection from "components/PageDashboard/SkypeIdSection";
import DefaultGoogleMeetLinkSection from "components/PageDashboard/DefaultGoogleMeetLinkSection";
import ICalSection from "components/PageDashboard/ICalSection";
import AlertBox from "components/AlertBox";
import SectionBox from "components/SectionBox";
import { usePageTitlePrefix } from "hooks/usePageTitlePrefix";
import { useAccountStatus } from "hooks/useAccountStatus";
import { useSaveDataAndRefreshAccountDetailsFromApi } from "hooks/useSaveDataAndRefreshAccountDetailsFromApi";
import ModalPleaseWait from "components/ModalPleaseWait";
import terminology from "terminology.json";
import { ucFirst } from "utils/ucFirst";

import NarrowSection from "./NarrowSection";
import WideSection from "./WideSection";
import SectionGroup from "./SectionGroup";

interface DashboardContextState {
	meetingsSectionRefreshNum: number;
	packagesSectionRefreshNum: number;
	sessionTypesRefreshNum: number;

	haveSessionTypes: boolean | undefined;
	haveSingleSessionPrices: boolean | undefined;
}

export const DashboardContext = React.createContext<
	[
		DashboardContextState,
		React.Dispatch<React.SetStateAction<DashboardContextState>>
	]
>([
	{
		meetingsSectionRefreshNum: 1,
		packagesSectionRefreshNum: 1,
		sessionTypesRefreshNum: 1,
		haveSessionTypes: undefined,
		haveSingleSessionPrices: undefined
	},
	arg => undefined
]);

const PageDashboard: React.FunctionComponent = () => {
	usePageTitlePrefix("Dashboard");
	const {
		isMentor,
		stripeAccountOnboardingStatus,
		stripeAccountPayoutsStatus,
		hasDismissedStripeAlert
	} = useAccountStatus();

	const [dashboardContextState, setDashboardContextState] = useState<
		DashboardContextState
	>({
		meetingsSectionRefreshNum: new Date().getTime(),
		packagesSectionRefreshNum: new Date().getTime(),
		sessionTypesRefreshNum: new Date().getTime(),
		haveSessionTypes: undefined,
		haveSingleSessionPrices: undefined
	});

	const needsToAddSessionTypes =
		isMentor && dashboardContextState.haveSessionTypes === false;
	const needsToAddSingleSessionPrices =
		isMentor && dashboardContextState.haveSingleSessionPrices === false;
	const yetToLinkStripe =
		isMentor &&
		(stripeAccountOnboardingStatus !== undefined &&
			stripeAccountOnboardingStatus !== "linked");
	const needsToSetUpStripe = isMentor && yetToLinkStripe;
	const stripeNeedsAttention =
		isMentor && stripeAccountPayoutsStatus === "disabled";

	const [isSaving, setIsSaving] = useState(false);
	const submitDataToAPI = useSaveDataAndRefreshAccountDetailsFromApi(
		"saveAccountSettings",
		"hiding the alert message",
		setIsSaving
	);

	// Contacts and Messages section collapsed-on-default state should be true for non-mentors and false for mentors.
	// The logic of this doesn't work if isMentor is allowed to be undefined while it is loading.  So, wait until
	// it has loaded before rendering this page.
	if (isMentor === undefined) {
		return null;
	}

	return (
		<DashboardContext.Provider
			value={[dashboardContextState, setDashboardContextState]}
		>
			<ModalPleaseWait isOpen={isSaving} title="Saving" />
			<PageContent type="admin">
				{(needsToAddSessionTypes ||
					needsToAddSingleSessionPrices ||
					needsToSetUpStripe ||
					stripeNeedsAttention) &&
				!hasDismissedStripeAlert ? (
					<AlertBox
						allowDismiss={
							needsToSetUpStripe &&
							!(
								needsToAddSessionTypes ||
								needsToAddSingleSessionPrices ||
								stripeNeedsAttention
							)
						}
						onDismiss={() =>
							submitDataToAPI({ data: { hasDismissedStripeAlert: true } })
						}
						contents={[
							...(needsToAddSessionTypes
								? [
										<>
											Until you add at least one{" "}
											<a href="#session-types-section">session type</a> your
											profile will show you as unavailable.
										</>
								  ]
								: []),
							...(needsToAddSingleSessionPrices
								? [
										<>
											You need to add some{" "}
											<a href="#session-types-section">prices</a> to go with
											your sessions.
										</>
								  ]
								: []),
							...(needsToSetUpStripe
								? [
										<>
											You need to{" "}
											<a href="#receiving-money-section">set up Stripe</a> if
											you want to receive card payments.
										</>
								  ]
								: []),
							...(stripeNeedsAttention
								? [
										<>
											Your Stripe account needs attention.{" "}
											<a href="#receiving-money-section">More information</a>.
										</>
								  ]
								: [])
						]}
					/>
				) : null}

				{isMentor ? null : (
					<>
						<WideSection
							title={`${ucFirst(terminology.mentors)}, contacts and messages`}
							isCollapsedByDefault={false}
						>
							<ContactsAndMessagesSection />
						</WideSection>
						<WideSection
							title={`${terminology.mentor} directory`}
							isCollapsedByDefault={false}
						>
							<p>
								If you need to find a {terminology.mentor}, you can use our{" "}
								<Link to={`/${terminology.mentorsInUrls}`}>
									{terminology.mentor} directory
								</Link>
							</p>
						</WideSection>
					</>
				)}

				{isMentor ? (
					<>
						<WideSection
							title={terminology.meetingRequests}
							isCollapsedByDefault={false}
						>
							<MeetingRequestsSection />
						</WideSection>
						<WideSection
							title={terminology.packageRequests}
							isCollapsedByDefault={false}
						>
							<PackageRequestsSection />
						</WideSection>
					</>
				) : null}
				<WideSection
					title={"Current " + terminology.meetings}
					isCollapsedByDefault={false}
				>
					<MeetingsSection />
				</WideSection>

				{isMentor ? (
					<SectionGroup title="Relationships">
						<WideSection title="Contacts and messages" isCollapsedByDefault>
							<ContactsAndMessagesSection />
						</WideSection>
						<WideSection
							title={"Active " + terminology.packages}
							isCollapsedByDefault
						>
							<PackagesSection />
						</WideSection>
						<WideSection
							title={`${terminology.mentor} directory`}
							isCollapsedByDefault
						>
							<p>
								If you want to {terminology.learn} as well as{" "}
								{terminology.teach}, you find a {terminology.mentor} using our{" "}
								<Link to={`/${terminology.mentorsInUrls}`}>
									{terminology.mentor} directory
								</Link>
							</p>
						</WideSection>
					</SectionGroup>
				) : (
					<WideSection
						title={"Active " + terminology.packages}
						isCollapsedByDefault={isMentor}
					>
						<PackagesSection />
					</WideSection>
				)}

				{isMentor ? (
					<SectionGroup title={`Availability`}>
						<WideSection title={`General availability`}>
							<AvailabilitySection />
						</WideSection>
						<WideSection title="External commitments">
							<ExternalCommitmentsSection />
						</WideSection>
					</SectionGroup>
				) : null}
				{isMentor ? (
					<SectionGroup title="Lessons and prices">
						<WideSection
							title={`${terminology.meeting} types`}
							anchorName="session-types-section"
						>
							<SessionTypesSection />
						</WideSection>
						<WideSection
							title={`Single ${terminology.meeting} prices`}
							anchorName="session-prices-section"
						>
							<SingleSessionPricesSection />
						</WideSection>
						<WideSection
							title={`${terminology.package} prices`}
							anchorName="package-prices-section"
						>
							<PackagePricesSection />
						</WideSection>
					</SectionGroup>
				) : null}
				{isMentor ? (
					<SectionGroup title="ICal">
						<WideSection
							title="ICal import"
							anchorName="iCal-feeds-to-import-section"
						>
							<ICalFeedsToImportSection />
						</WideSection>
						<WideSection title="ICal export">
							<ICalSection />
						</WideSection>
					</SectionGroup>
				) : (
					<WideSection title="ICal export">
						<ICalSection />
					</WideSection>
				)}

				{isMentor ? (
					<SectionGroup title="Profile">
						<WideSection title="Basic data and account settings">
							<AccountSettingsSection />
						</WideSection>
						<div className="row gtr-uniform">
							<NarrowSection title="Photo">
								<ProfilePhotoSection />
							</NarrowSection>
							<NarrowSection title="Video">
								<SectionBox>
									<ProfileVideoSection />
								</SectionBox>
							</NarrowSection>
						</div>
						<WideSection title="URL">
							<SectionBox>
								<ProfileUrlSection />
							</SectionBox>
						</WideSection>
					</SectionGroup>
				) : null}

				{isMentor ? (
					<>
						<SectionGroup title="Video chat platforms">
							<div className="row gtr-uniform">
								<NarrowSection title="Skype ID">
									<SectionBox>
										<SkypeIdSection />
									</SectionBox>
								</NarrowSection>
								<NarrowSection title="Default Google Meet link">
									<SectionBox>
										<DefaultGoogleMeetLinkSection />
									</SectionBox>
								</NarrowSection>
							</div>
						</SectionGroup>

						<WideSection
							title="Receiving money (payment providers)"
							anchorName="receiving-money-section"
						>
							<SectionBox
								highlight={needsToSetUpStripe || stripeNeedsAttention}
							>
								<ReceivingMoneySection />
							</SectionBox>
						</WideSection>
					</>
				) : (
					<WideSection title="Skype ID">
						<SectionBox>
							<SkypeIdSection />
						</SectionBox>
					</WideSection>
				)}

				{isMentor ? null : (
					<WideSection
						title="Teacher account"
						anchorName="teacher-account-section"
						isCollapsedByDefault={false}
					>
						<SectionBox>
							<p>
								If you would to apply to upgrade your account to a{" "}
								{terminology.mentor} account, so that you can use the{" "}
								{terminology.mentor}-specific features, please send an email to{" "}
								<a href="mailto:support@aldeia.world">support@aldeia.world</a>.
							</p>
						</SectionBox>
					</WideSection>
				)}

				<WideSection title="Delete account" anchorName="delete-account-section">
					<SectionBox>
						<DeleteAccountSection />
					</SectionBox>
				</WideSection>
			</PageContent>
		</DashboardContext.Provider>
	);
};

export default PageDashboard;
