import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import { formatList } from "utils/formatList";
import { ucFirst } from "utils/ucFirst";
import { getTermsByCategory } from "services/getTermsByCategory";
import { useProfileData } from "hooks/useProfileData";
import Avatar from "components/Avatar";
import terminology from "terminology.json";

const StyledHeading = styled.h2`
	/* Prevent Bootstrap from overriding the usual margin (which it does for some reason if this heading is the last child in its generation */
	margin-bottom: 1em !important;

	line-height: 3em;
`;

const Heading: React.FunctionComponent = () => {
	const { vanityUrl, uid } = useParams<{ vanityUrl: string; uid: string }>();

	const { output: profileData } = useProfileData(vanityUrl, uid);
	const canhelpwith = getTermsByCategory(profileData, "canhelpwith");

	return (
		<StyledHeading>
			{profileData ? (
				<>
					{profileData.isMentor ? (
						<>
							{canhelpwith.length
								? `Learn ${formatList({
										list: canhelpwith.map(t => t.text),
										isDisjunction: true
								  })} ${
										profileData && profileData.displayName
											? `with ${profileData && profileData.displayName}`
											: `online`
								  } `
								: profileData && profileData.displayName
								? profileData && profileData.displayName
								: `${ucFirst(terminology.mentor)} profile`}
							&#8203;
							{profileData && profileData.avatarUrl ? (
								<Avatar vanityUrl={vanityUrl} uid={uid} />
							) : null}
						</>
					) : (
						profileData.displayName
					)}
				</>
			) : null}
		</StyledHeading>
	);
};

export default Heading;
